<template>
  <div class="swipe">
    <div class="left">
      <p class="left-title">店内环境图：<span>(图片建议尺寸750*420，大小在500kb以内)</span></p>
      <li v-for="(item, index) in imgList" class="img-list" :key="index">
        <el-image class="img-item" :src="item.adPicUrl" :preview-src-list="[item.adPicUrl]">
        </el-image>
        <div class="img-edit">
          <i class="el-icon-edit" @click="onReplace(item)"></i>
          <i class="el-icon-top" @click="onUp(item)" v-if="index != 0"></i>
          <i class="el-icon-bottom" @click="onDown(item)" v-if="index != imgList.length - 1"></i>
          <i class="el-icon-delete" @click="onDel(item)"></i>
        </div>
      </li>
      <div class="upload-btn" @click="onUpload('轮播图')">
        <i class="el-icon-plus uploader-icon"></i>
      </div>
    </div>
    <div class="right">
      <p>小程序展示样式</p>
      <img src="~@/assets/images/mini/swipe.png" alt="">
    </div>
    <!-- 图片上传组件 -->
    <UpLoad @fileIds="getFileIds" ref="up" :fileType="1"></UpLoad>
  </div>
</template>

<script>
import UpLoad from "@/components/upload";
import {
  getImgList,
  getImgEdit,
  getImgChange,
  getImgDel,
} from "@/api/shop/mini";
import { rules } from "@/db/rules";

export default {
  components: { UpLoad },
  data() {
    return {
      rules,
      imgList: [],//图片列表
      uploadTitle: "",
      params: {
        shopId: "",//店铺ID
        adId: "", //轮播ID
        adPic: "", //图片id集合
      },
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
    if (merchantShopId) {
      this.params.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getImgList();
  },
  methods: {
    // 【请求】小程序轮播图列表
    getImgList() {
      let data = {
        shopId: this.params.shopId
      };
      getImgList(data).then(res => {
        if (res.isSuccess == "yes") {
          if (res.data) {
            this.imgList = res.data;
          }
        }
      })
    },

    // 【请求】小程序轮播图上传
    getImgEdit() {
      let data = this.params
      getImgEdit(data).then(res => {
        if (res.isSuccess == "yes") {
          this.getImgList();
        }
      })
    },

    // 【监听】获取上传文件ID集合
    getFileIds(fileIds) {
      this.params.adPic = fileIds;
      this.getImgEdit();
    },

    // 【监听】文件上传
    onUpload(title) {
      this.uploadTitle = title;
      if (this.uploadTitle == "轮播图") {
        this.params = {
          adId: "", //轮播ID
          adPic: "", //图片id集合
          shopId: this.params.shopId,//店铺ID
        }
      }
      this.$refs.up.onUpload();
    },

    // 【监听】图片编辑
    onReplace(row) {
      this.params = JSON.parse(JSON.stringify(row));
      this.$refs.up.onUpload();
    },

    // 【监听】图片上移
    onUp(row) {
      row.changeType = 1;
      this.$confirm('你确定要上移吗?', '上移', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        getImgChange(row).then(res => {
          if (res.isSuccess == "yes") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.getImgList();
          }
        })
      });
    },

    // 【监听】图片下移
    onDown(row) {
      row.changeType = 2;
      this.$confirm('你确定要下移吗?', '下移', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        getImgChange(row).then(res => {
          if (res.isSuccess == "yes") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.getImgList();
          }
        })
      });
    },

    // 【监听】图片删除
    onDel(row) {
      this.$confirm('你确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        getImgDel(row).then(res => {
          if (res.isSuccess == "yes") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.getImgList();
          }
        })
      });
    },
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/theme';

.swipe {
  display: flex;
}

.upload-btn {
  border: 1px dashed $theme-color;
  border-radius: 6px;
  cursor: pointer;
  width: 4rem;
  height: 1.5rem;
}

.upload-btn:hover {
  border-color: $theme-color;
}

.uploader-icon {
  font-size: 28px;
  color: $theme-color;
  width: 4rem;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
}

.img-list {
  margin: .1rem 0;
  display: flex;
}

.img-edit {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: .2rem;
  width: 2.5rem;

  >i {
    font-size: .2rem;
    cursor: pointer;
  }
}

.img-edit>i:hover {
  color: $theme-color;
}

.img-item {
  width: 4rem;
  height: 1.5rem;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
}

.left{
  width: 60%;
}

.left-title {
  margin-bottom: .15rem;
  span {
    font-size: .14rem;
    color: #999999;
  }
}

.right {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: .1rem 0;
  }
}
</style>
